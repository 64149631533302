import { Message, FileNode } from '../types';

interface ProjectState {
    files: FileNode[];
    selectedFilePath?: string;
}

interface SerializableFileNode {
    name: string;
    path: string;
    type: 'file' | 'directory';
    content?: string;
    expanded?: boolean;
    children?: SerializableFileNode[];
    timestamp: string;
}

class ProjectService {
    private static readonly STORAGE_KEY = 'code-reader-project-state';
    private static instance: ProjectService;

    private constructor() {}

    public static getInstance(): ProjectService {
        if (!ProjectService.instance) {
            ProjectService.instance = new ProjectService();
        }
        return ProjectService.instance;
    }

    public saveProject(state: ProjectState): void {
        try {
            const serializableFiles = this.makeFilesSerializable(state.files);
            
            const storageState = {
                files: serializableFiles,
                selectedFilePath: state.selectedFilePath,
                timestamp: new Date().toISOString()
            };
            
            localStorage.setItem(ProjectService.STORAGE_KEY, JSON.stringify(storageState));
            console.log('Project saved:', { 
                filesCount: serializableFiles.length,
                selectedFile: state.selectedFilePath 
            });
        } catch (error) {
            console.error('Error saving project:', error);
        }
    }

    public loadProject(): ProjectState | null {
        try {
            const savedState = localStorage.getItem(ProjectService.STORAGE_KEY);
            if (!savedState) return null;

            const state = JSON.parse(savedState);
            console.log('Project loaded from:', state.timestamp);
            
            return {
                files: this.reconstructFiles(state.files),
                selectedFilePath: state.selectedFilePath
            };
        } catch (error) {
            console.error('Error loading project:', error);
            return null;
        }
    }

    private makeFilesSerializable(files: FileNode[]): SerializableFileNode[] {
        return files.map(file => ({
            name: file.name,
            path: file.path,
            type: file.type,
            content: file.content,
            expanded: file.expanded,
            children: file.children ? this.makeFilesSerializable(file.children) : undefined,
            timestamp: new Date().toISOString()
        }));
    }

    private reconstructFiles(files: SerializableFileNode[]): FileNode[] {
        return files.map(file => {
            const reconstructedFile: FileNode = {
                name: file.name,
                path: file.path,
                type: file.type,
                content: file.content,
                expanded: file.expanded ?? false,
                children: file.children ? this.reconstructFiles(file.children) : undefined
                // fileHandle is optional in FileNode, so we can omit it
            };
            return reconstructedFile;
        });
    }

    public clearProject(): void {
        localStorage.removeItem(ProjectService.STORAGE_KEY);
        console.log('Project cleared');
    }
}

export const projectService = ProjectService.getInstance();
