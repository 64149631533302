import React, { useState, useEffect, useContext, useRef } from 'react';
import { Message, FileNode, EditorSettings } from './types';
import Chat from './components/Chat';
import FileTree from './components/FileTree';
import CodeViewer from './components/CodeViewer';
import Settings from './components/Settings';
import Toolbar from './components/Toolbar';
import About from './components/About';
import { llmService } from './services/LLMService';
import { projectService } from './services/ProjectService';
import { SettingsContext } from './contexts/SettingsContext';
import 'highlight.js/styles/github.css';
import './App.css';
import './styles/panels.css';
import EditorSettingsDialog from './components/EditorSettings';
import ChatHistory from './components/ChatHistory';
import { Panel, PanelGroup, PanelResizeHandle, ImperativePanelHandle } from 'react-resizable-panels';

function App() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [files, setFiles] = useState<FileNode[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileNode | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [showSettings, setShowSettings] = useState(false);
  const [showEditorSettings, setShowEditorSettings] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [editorSettings, setEditorSettings] = useState<EditorSettings>({
    theme: 'vs',
    fontSize: 14,
    tabSize: 2,
    wordWrap: true,
  });
  const [chatHistory, setChatHistory] = useState<Message[][]>([]);
  const { settings } = useContext(SettingsContext);
  const chatPanelRef = useRef<ImperativePanelHandle>(null);

  // Initialize LLM service with settings
  useEffect(() => {
    console.log('App: Settings changed:', {
      hasApiKey: !!settings.apiKey,
      apiUrl: settings.apiUrl,
      model: settings.model
    });
    llmService.updateSettings(settings);
  }, [settings]);

  // Load saved project state
  useEffect(() => {
    console.log('Loading initial project state...');
    const savedState = projectService.loadProject();
    if (savedState) {
      console.log('Setting files from saved state:', savedState.files.length);
      setFiles(savedState.files);
      
      if (savedState.selectedFilePath) {
        const findFile = (files: FileNode[]): FileNode | null => {
          for (const file of files) {
            if (file.path === savedState.selectedFilePath) {
              return file;
            }
            if (file.children) {
              const found = findFile(file.children);
              if (found) return found;
            }
          }
          return null;
        };
        
        const selectedFile = findFile(savedState.files);
        if (selectedFile) {
          console.log('Restoring selected file:', selectedFile.path);
          setSelectedFile(selectedFile);
          setFileContent(selectedFile.content || '');
        }
      }
    }
  }, []);

  // Save project state when files change
  useEffect(() => {
    if (files.length > 0) {
      console.log('Saving project state, files:', files.length);
      projectService.saveProject({
        files,
        selectedFilePath: selectedFile?.path
      });
    }
  }, [files, selectedFile?.path]);

  // Initialize messages from LLM service
  useEffect(() => {
    const context = llmService['contexts'].get('main-dialog');
    if (context) {
      setMessages(context.messages);
    }
  }, []);

  const isImageFile = (filename: string): boolean => {
    const ext = filename.split('.').pop()?.toLowerCase() || '';
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(ext);
  };

  const handleFileSelect = (file: FileNode) => {
    setSelectedFile(file);
    if (file.content) {
      setFileContent(file.content);
    } else {
      (file.fileHandle as FileSystemFileEntry).file((actualFile: File) => {
        if (isImageFile(file.name)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target?.result as string;
            setFileContent('');  // Clear text content for images
            // Update the file node with the image data URL
            setFiles(prev => {
              const updateFileContent = (files: FileNode[]): FileNode[] => {
                return files.map(f => {
                  if (f.path === file.path) {
                    return { ...f, content };
                  }
                  if (f.children) {
                    return { ...f, children: updateFileContent(f.children) };
                  }
                  return f;
                });
              };
              return updateFileContent(prev);
            });
          };
          reader.readAsDataURL(actualFile);
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            setFileContent(e.target?.result as string);
          };
          reader.readAsText(actualFile);
        }
      });
    }
  };

  const handleSendMessage = async (message: string, attachedFiles?: { id: string; name: string; path: string }[]) => {
    const findFileContent = (path: string): string | undefined => {
      const findInNodes = (nodes: FileNode[]): string | undefined => {
        for (const node of nodes) {
          if (node.path === path && node.content) {
            return node.content;
          }
          if (node.children) {
            const found = findInNodes(node.children);
            if (found) return found;
          }
        }
        return undefined;
      };
      return findInNodes(files);
    };

    const formattedFiles = attachedFiles?.map(file => {
      const content = findFileContent(file.path);
      return content ? { name: file.name, content } : undefined;
    }).filter((file): file is { name: string; content: string } => file !== undefined);

    try {
      // Add user message to the state
      const userMessage: Message = { role: 'user', content: message };
      setMessages(prev => [...prev, userMessage]);

      // Send message to LLM service
      const response = await llmService.sendMessage(
        'main-dialog',
        message,
        formattedFiles
      );
      
      // Add assistant response to the state
      setMessages(prev => [...prev, response]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleDirectoryToggle = (dir: FileNode) => {
    const updateFiles = (files: FileNode[]): FileNode[] => {
      return files.map(file => {
        if (file.path === dir.path) {
          return { ...file, expanded: !file.expanded };
        }
        if (file.children) {
          return { ...file, children: updateFiles(file.children) };
        }
        return file;
      });
    };
    setFiles(updateFiles(files));
  };

  const handleFileDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    console.log('Files dropped');
    const items = Array.from(e.dataTransfer.items);
    
    const processEntry = async (entry: FileSystemEntry): Promise<FileNode> => {
      return new Promise((resolve) => {
        if (entry.isFile) {
          const fileEntry = entry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if (isImageFile(entry.name)) {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  name: entry.name,
                  path: entry.fullPath,
                  type: 'file',
                  content: reader.result as string
                });
              };
              reader.readAsDataURL(file);
            } else {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  name: entry.name,
                  path: entry.fullPath,
                  type: 'file',
                  content: reader.result as string
                });
              };
              reader.readAsText(file);
            }
          });
        } else if (entry.isDirectory) {
          const dirEntry = entry as FileSystemDirectoryEntry;
          const dirReader = dirEntry.createReader();
          dirReader.readEntries(async (entries: FileSystemEntry[]) => {
            const children = await Promise.all(
              entries.map(entry => processEntry(entry))
            );
            resolve({
              name: entry.name,
              path: entry.fullPath,
              type: 'directory',
              expanded: true,
              children
            });
          });
        }
      });
    };

    try {
      const newFiles = await Promise.all(
        items
          .filter(item => item.kind === 'file')
          .map(item => processEntry(item.webkitGetAsEntry() as FileSystemEntry))
      );
      
      console.log('Processing dropped files:', newFiles.length);
      setFiles(prevFiles => {
        const updatedFiles = [...prevFiles, ...newFiles];
        return updatedFiles;
      });
    } catch (error) {
      console.error('Error processing dropped files:', error);
    }
  };

  const handleNewProject = () => {
    if (window.confirm('Are you sure you want to start a new project? All unsaved changes will be lost.')) {
      setFiles([]);
      setSelectedFile(null);
      setFileContent('');
      setMessages([]);
      setChatHistory([]);
      llmService.clearContext('main-dialog');
      projectService.clearProject();
    }
  };

  const handleSaveProject = () => {
    projectService.saveProject({
      files,
      selectedFilePath: selectedFile?.path
    });
  };

  const handleSaveAsProject = () => {
    // TODO: Implement save as functionality
    alert('Save As functionality coming soon!');
  };

  const handleEditorSettingsSave = (newSettings: EditorSettings) => {
    setEditorSettings(newSettings);
    // TODO: Implement editor settings persistence
  };

  const handleNewChat = () => {
    if (messages.length > 0) {
      setChatHistory(prev => [...prev, messages]);
    }
    setMessages([]);
  };

  const handleSelectChat = (chatIndex: number) => {
    if (messages.length > 0) {
      setChatHistory(prev => [...prev, messages]);
    }
    setMessages(chatHistory[chatIndex]);
    setChatHistory(prev => prev.filter((_, index) => index !== chatIndex));
  };

  const handleResize = (sizes: number[]) => {
    if (sizes.length === 2) {
      const containerWidth = document.querySelector('.flex-1.flex.min-w-0')?.clientWidth || 0;
      const chatWidth = (containerWidth * sizes[1]) / 100;
      
      if (chatWidth < 300) {
        const newSize = (300 / containerWidth) * 100;
        chatPanelRef.current?.resize(newSize);
      } else if (chatWidth > 600) {
        const newSize = (600 / containerWidth) * 100;
        chatPanelRef.current?.resize(newSize);
      }
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <Toolbar
        onNewProject={handleNewProject}
        onSaveProject={handleSaveProject}
        onSaveAsProject={handleSaveAsProject}
        onOpenSettings={() => setShowSettings(true)}
        onOpenEditorSettings={() => setShowEditorSettings(true)}
        onOpenAbout={() => setShowAbout(true)}
      />
      <div className="flex-1 flex overflow-hidden">
        <div className="min-w-[200px] w-64 bg-white border-r shrink-0">
          <FileTree
            files={files}
            selectedFile={selectedFile}
            onFileSelect={handleFileSelect}
            onDirectoryToggle={handleDirectoryToggle}
            onFileDrop={handleFileDrop}
          />
        </div>
        <div className="flex-1 flex min-w-0">
          <PanelGroup direction="horizontal" onLayout={handleResize}>
            <Panel defaultSize={65}>
              <div className="h-full w-full overflow-auto" style={{ minWidth: '500px' }}>
                {selectedFile && (
                  <CodeViewer
                    selectedFile={selectedFile}
                    fileContent={fileContent}
                  />
                )}
              </div>
            </Panel>
            <PanelResizeHandle className="w-1 bg-gray-200 hover:bg-gray-300 transition-colors duration-150 cursor-col-resize" />
            <Panel
              ref={chatPanelRef}
              defaultSize={35}
            >
              <div className="h-full w-full flex flex-col">
                <Chat
                  messages={messages}
                  onSendMessage={handleSendMessage}
                  onNewChat={handleNewChat}
                  setShowHistory={setShowHistory}
                  files={files}
                />
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </div>
      <Settings isOpen={showSettings} onClose={() => setShowSettings(false)} />
      <EditorSettingsDialog
        isOpen={showEditorSettings}
        onClose={() => setShowEditorSettings(false)}
        settings={editorSettings}
        onSave={handleEditorSettingsSave}
      />
      <About isOpen={showAbout} onClose={() => setShowAbout(false)} />
      {showHistory && (
        <ChatHistory
          visible={showHistory}
          onClose={() => setShowHistory(false)}
          chats={[...chatHistory, messages].filter(chat => chat.length > 0)}
          onSelectChat={handleSelectChat}
        />
      )}
    </div>
  );
}

export default App;
