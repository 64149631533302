import React from 'react';

interface ToolbarProps {
  onNewProject: () => void;
  onSaveProject: () => void;
  onSaveAsProject: () => void;
  onOpenSettings: () => void;
  onOpenEditorSettings: () => void;
  onOpenAbout: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({
  onNewProject,
  onSaveProject,
  onSaveAsProject,
  onOpenSettings,
  onOpenEditorSettings,
  onOpenAbout,
}) => {
  return (
    <div className="bg-gray-800 text-white p-2 flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <button
          onClick={onNewProject}
          className="px-3 py-1 rounded hover:bg-gray-700 transition-colors"
        >
          New
        </button>
        <button
          onClick={onSaveProject}
          className="px-3 py-1 rounded hover:bg-gray-700 transition-colors"
        >
          Save
        </button>
        <button
          onClick={onSaveAsProject}
          className="px-3 py-1 rounded hover:bg-gray-700 transition-colors"
        >
          Save As
        </button>
      </div>

      <div className="flex items-center space-x-2">
        <button
          onClick={onOpenEditorSettings}
          className="p-2 rounded hover:bg-gray-700 transition-colors"
          title="Editor Settings"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
        </button>
        <button
          onClick={onOpenSettings}
          className="p-2 rounded hover:bg-gray-700 transition-colors"
          title="API Settings"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
          </svg>
        </button>
        <button
          onClick={onOpenAbout}
          className="px-3 py-1 rounded hover:bg-gray-700 transition-colors"
        >
          About
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
