import React from 'react';
import { FileNode } from '../types';

interface FileTreeProps {
    files: FileNode[];
    selectedFile: FileNode | null;
    onFileSelect: (file: FileNode) => void;
    onDirectoryToggle: (dir: FileNode) => void;
    onFileDrop: (e: React.DragEvent) => void;
}

const FileTree: React.FC<FileTreeProps> = ({
    files,
    selectedFile,
    onFileSelect,
    onDirectoryToggle,
    onFileDrop,
}) => {
    const renderFile = (file: FileNode) => {
        const isSelected = selectedFile?.path === file.path;
        
        return (
            <div key={file.path} className="my-1">
                <div 
                    onClick={() => file.type === 'directory' ? onDirectoryToggle(file) : onFileSelect(file)}
                    className={`flex items-center cursor-pointer hover:bg-gray-100 p-1 rounded ${
                        isSelected ? 'bg-blue-100' : ''
                    }`}
                >
                    <span className="mr-2">
                        {file.type === 'directory' 
                            ? (file.expanded ? '📂' : '📁') 
                            : '📄'}
                    </span>
                    <span>{file.name}</span>
                </div>
                {file.type === 'directory' && file.expanded && file.children && (
                    <div className="ml-4 space-y-1">
                        {file.children.map(child => renderFile(child))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="w-64 flex-shrink-0 bg-white border-r overflow-y-auto">
            <h2 className="text-xl font-bold mb-4 p-4">Files</h2>
            <div 
                className={`${
                    files.length === 0 
                        ? 'border-2 border-dashed border-gray-300' 
                        : 'border border-gray-200'
                } rounded-lg p-4 min-h-[200px] transition-colors duration-200 mx-4 ${
                    files.length > 0 ? 'bg-white' : ''
                }`}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.add('border-blue-500');
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove('border-blue-500');
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove('border-blue-500');
                    onFileDrop(e);
                }}
            >
                {files.length === 0 ? (
                    <div className="text-center text-gray-500">
                        Drop files or folders here
                    </div>
                ) : (
                    <div className="space-y-1">
                        {files.map(file => renderFile(file))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileTree;
