import React, { useContext, useState, useEffect } from 'react';
import { OpenAISettings } from '../types';
import { SettingsContext } from '../contexts/SettingsContext';

interface SettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

const Settings: React.FC<SettingsProps> = ({ isOpen, onClose }) => {
  const { settings, updateSettings } = useContext(SettingsContext);
  const [localSettings, setLocalSettings] = useState<OpenAISettings>(settings);

  // Update local settings when context settings change
  useEffect(() => {
    setLocalSettings(settings);
  }, [settings]);

  if (!isOpen) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setLocalSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = () => {
    if (!localSettings.apiKey?.trim()) {
      alert('Please enter a valid API key');
      return;
    }
    updateSettings(localSettings);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Settings</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
        </div>
        
        <div className="space-y-4">
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">API Key</label>
            <input
              type="password"
              name="apiKey"
              value={localSettings.apiKey}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your OpenAI API key"
            />
          </div>

          <div className="form-group">
            <label className="block text-sm font-medium mb-1">API URL</label>
            <input
              type="text"
              name="apiUrl"
              value={localSettings.apiUrl}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              placeholder="https://api.openai.com/v1"
            />
          </div>

          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Model</label>
            <select
              name="model"
              value={localSettings.model}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
            >
              <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
              <option value="gpt-4">GPT-4</option>
              <option value="custom">Custom Model</option>
            </select>
          </div>

          {localSettings.model === 'custom' && (
            <div className="form-group">
              <label className="block text-sm font-medium mb-1">Custom Model Name</label>
              <input
                type="text"
                name="customModel"
                value={localSettings.customModel}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                placeholder="Enter custom model name"
              />
            </div>
          )}
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
