import React, { createContext, useState, useEffect } from 'react';
import { OpenAISettings, SettingsContextType } from '../types';

const defaultSettings: OpenAISettings = {
  apiKey: '',
  apiUrl: 'https://api.openai.com/v1',
  model: 'gpt-3.5-turbo',
  customModel: '',
};

export const SettingsContext = createContext<SettingsContextType>({
  settings: defaultSettings,
  updateSettings: () => {},
});

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<OpenAISettings>(() => {
    try {
      const savedSettings = localStorage.getItem('openAISettings');
      console.log('SettingsContext: Loading saved settings');
      if (savedSettings) {
        const parsed = JSON.parse(savedSettings);
        console.log('SettingsContext: Loaded settings:', {
          hasApiKey: !!parsed.apiKey,
          apiUrl: parsed.apiUrl,
          model: parsed.model
        });
        return parsed;
      }
    } catch (error) {
      console.error('Error loading settings:', error);
    }
    console.log('SettingsContext: Using default settings');
    return defaultSettings;
  });

  useEffect(() => {
    console.log('SettingsContext: Saving settings:', {
      hasApiKey: !!settings.apiKey,
      apiUrl: settings.apiUrl,
      model: settings.model
    });
    localStorage.setItem('openAISettings', JSON.stringify(settings));
  }, [settings]);

  const updateSettings = (newSettings: Partial<OpenAISettings>) => {
    console.log('SettingsContext: Updating settings:', {
      hasApiKey: !!newSettings.apiKey,
      apiUrl: newSettings.apiUrl,
      model: newSettings.model
    });
    setSettings(prev => ({
      ...prev,
      ...newSettings,
    }));
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
