import React from 'react';

interface AboutProps {
  isOpen: boolean;
  onClose: () => void;
}

const About: React.FC<AboutProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">About</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
        </div>
        
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Code Reader React</h3>
          <p>
            A powerful code reading and analysis tool built with React and TypeScript.
            This application helps developers understand and navigate codebases more effectively.
          </p>
          
          <h4 className="text-md font-semibold">Features:</h4>
          <ul className="list-disc pl-5">
            <li>Interactive file tree navigation</li>
            <li>Code syntax highlighting</li>
            <li>AI-powered code analysis</li>
            <li>Project state management</li>
            <li>Customizable settings</li>
          </ul>
          
          <p className="text-sm text-gray-600 mt-4">
            Version: 1.0.0
          </p>
        </div>

        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
