import { Message } from '../types';
import { OpenAISettings } from '../types';

interface LLMRequestOptions {
    max_tokens?: number;
    temperature?: number;
    model?: string;
}

interface DialogContext {
    messages: Message[];
    metadata?: Record<string, any>;
}

export class LLMService {
    private static instance: LLMService;
    private contexts: Map<string, DialogContext>;
    private defaultOptions: LLMRequestOptions;
    private settings: OpenAISettings | null = null;
    private static readonly STORAGE_KEY = 'llm-service-state';

    private constructor() {
        this.contexts = new Map();
        this.defaultOptions = {
            max_tokens: 2000,
            temperature: 0.7,
            model: 'gpt-3.5-turbo'
        };
        this.loadState();
    }

    public static getInstance(): LLMService {
        if (!LLMService.instance) {
            LLMService.instance = new LLMService();
        }
        return LLMService.instance;
    }

    private saveState(): void {
        try {
            const state = {
                contexts: Array.from(this.contexts.entries()).map(([id, context]) => ({
                    id,
                    messages: context.messages,
                    metadata: context.metadata
                }))
            };
            localStorage.setItem(LLMService.STORAGE_KEY, JSON.stringify(state));
        } catch (error) {
            console.error('Error saving LLM state:', error);
        }
    }

    private loadState(): void {
        try {
            const savedState = localStorage.getItem(LLMService.STORAGE_KEY);
            if (savedState) {
                const state = JSON.parse(savedState);
                this.contexts.clear();
                state.contexts.forEach((context: any) => {
                    this.contexts.set(context.id, {
                        messages: context.messages,
                        metadata: context.metadata
                    });
                });
            }
        } catch (error) {
            console.error('Error loading LLM state:', error);
        }
    }

    public updateSettings(settings: OpenAISettings) {
        console.log('LLMService: Updating settings:', { 
            apiKey: settings.apiKey ? '***' : 'not set',
            apiUrl: settings.apiUrl,
            model: settings.model 
        });
        this.settings = settings;
    }

    private getSystemPrompt(attachedFiles?: { name: string; content: string }[]): string {
        let systemPrompt = `You are an AI assistant specialized in analyzing and modifying code.
When providing solutions:
1. Focus on clarity and maintainability
2. Follow best practices and conventions
3. Include necessary error handling
4. Consider edge cases
5. Provide TypeScript types when relevant
6. Keep responses concise and code-focused
7. Use comments to indicate unchanged code sections

Instructions for the output format:
- Output code without descriptions, unless it is important.
- Minimize prose, comments and empty lines.
- Only show the relevant code that needs to be modified. Use comments to represent the parts that are not modified.
- Make it easy to copy and paste.
- Consider other possibilities to achieve the result, do not be limited by the prompt.`;

        if (attachedFiles && attachedFiles.length > 0) {
            systemPrompt += '\n\nReference files:\n';
            attachedFiles.forEach(file => {
                systemPrompt += `\n--${file.name}\n${file.content}\n`;
            });
        }

        return systemPrompt;
    }

    private getOrCreateContext(dialogId: string): DialogContext {
        let context = this.contexts.get(dialogId);
        if (!context) {
            context = {
                messages: [],
                metadata: {}
            };
            this.contexts.set(dialogId, context);
        }
        return context;
    }

    public async sendMessage(
        dialogId: string,
        content: string,
        attachedFiles?: { name: string; content: string }[],
        options?: LLMRequestOptions
    ): Promise<Message> {
        if (!this.settings?.apiKey || this.settings.apiKey.trim() === '') {
            throw new Error('Please configure your OpenAI API key in settings first.');
        }

        const context = this.getOrCreateContext(dialogId);
        const systemPrompt = this.getSystemPrompt(attachedFiles);
        
        // Add user message to context
        const userMessage: Message = {
            role: 'user',
            content
        };
        context.messages.push(userMessage);

        try {
            const apiUrl = this.settings.apiUrl.endsWith('/v1') 
                ? this.settings.apiUrl 
                : `${this.settings.apiUrl}/v1`;
                
            const response = await fetch(`${apiUrl}/chat/completions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.settings.apiKey}`
                },
                body: JSON.stringify({
                    model: this.settings.model === 'custom' ? this.settings.customModel : this.settings.model,
                    messages: [
                        { role: 'system', content: systemPrompt },
                        ...context.messages
                    ],
                    max_tokens: this.defaultOptions.max_tokens,
                    temperature: this.defaultOptions.temperature,
                    ...options
                })
            });

            if (!response.ok) {
                const error = await response.json().catch(() => ({ error: { message: response.statusText } }));
                throw new Error(error.error?.message || `API request failed: ${response.statusText}`);
            }

            const data = await response.json();
            const assistantMessage: Message = {
                role: 'assistant',
                content: data.choices[0].message.content
            };

            context.messages.push(assistantMessage);
            this.saveState(); // Save state after adding new messages
            return assistantMessage;
        } catch (error) {
            console.error('Error calling LLM API:', error);
            throw error;
        }
    }

    public clearContext(contextId: string): void {
        this.contexts.set(contextId, {
          messages: [],
          metadata: {}
        });
        this.saveState();
    }
}

export const llmService = LLMService.getInstance();
