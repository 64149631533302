import React, { useState, useContext } from 'react';
import { Message, FileNode } from '../types';
import { marked } from 'marked';
import hljs from 'highlight.js';
import DOMPurify from 'dompurify';
import { SettingsContext } from '../contexts/SettingsContext';

interface AttachedFile {
    id: string;
    name: string;
    path: string;
}

interface ChatProps {
    messages: Message[];
    onSendMessage: (message: string, attachedFiles?: AttachedFile[]) => void;
    onNewChat: () => void;
    setShowHistory: (show: boolean) => void;
    files: FileNode[];
}

const Chat: React.FC<ChatProps> = ({ messages, onSendMessage, onNewChat, setShowHistory, files }) => {
    const [input, setInput] = useState('');
    const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
    const [expandedDirs, setExpandedDirs] = useState<Set<string>>(new Set());
    const { settings } = useContext(SettingsContext);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!settings.apiKey) {
            alert('Please configure your OpenAI API key in settings first.');
            return;
        }
        if (input.trim()) {
            onSendMessage(input.trim(), attachedFiles);
            setInput('');
            setAttachedFiles([]);
            setSelectedFiles(new Set());
        }
    };

    const handleFileSelect = () => {
        // When opening the modal, expand all root-level directories
        const rootDirs = files.filter(file => file.type === 'directory').map(dir => dir.path);
        setExpandedDirs(new Set(rootDirs));
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleFileToggle = (file: FileNode) => {
        if (file.type === 'file') {
            const newSelectedFiles = new Set(selectedFiles);
            if (selectedFiles.has(file.path)) {
                newSelectedFiles.delete(file.path);
            } else {
                newSelectedFiles.add(file.path);
            }
            setSelectedFiles(newSelectedFiles);
        }
    };

    const handleConfirmSelection = () => {
        const newFiles = Array.from(selectedFiles).map(path => ({
            id: `${path}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
            name: path.split('/').pop() || path,
            path: path
        }));
        setAttachedFiles(prev => [...prev, ...newFiles]);
        setIsModalOpen(false);
    };

    const handleRemoveFile = (fileId: string) => {
        setAttachedFiles(prev => prev.filter(file => file.id !== fileId));
        setSelectedFiles(prev => {
            const newSet = new Set(prev);
            const file = attachedFiles.find(f => f.id === fileId);
            if (file) {
                newSet.delete(file.path);
            }
            return newSet;
        });
    };

    const handleDirectoryToggle = (dir: FileNode) => {
        setExpandedDirs(prev => {
            const newSet = new Set(prev);
            if (newSet.has(dir.path)) {
                newSet.delete(dir.path);
            } else {
                newSet.add(dir.path);
            }
            return newSet;
        });
    };

    const renderFileTree = (fileNode: FileNode) => {
        return (
            <div key={fileNode.path} className="my-1">
                <div className="flex items-center">
                    {fileNode.type === 'file' && (
                        <input
                            type="checkbox"
                            checked={selectedFiles.has(fileNode.path)}
                            onChange={() => handleFileToggle(fileNode)}
                            className="mr-2"
                        />
                    )}
                    <span 
                        className="mr-2 cursor-pointer" 
                        onClick={() => fileNode.type === 'directory' && handleDirectoryToggle(fileNode)}
                    >
                        {fileNode.type === 'directory' 
                            ? (expandedDirs.has(fileNode.path) ? '📂' : '📁') 
                            : '📄'}
                    </span>
                    <span>{fileNode.name}</span>
                </div>
                {fileNode.type === 'directory' && expandedDirs.has(fileNode.path) && fileNode.children && (
                    <div className="ml-4">
                        {fileNode.children.map(child => renderFileTree(child))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="flex-1 flex flex-col border-l overflow-hidden">
            <div className="p-4 border-b flex justify-between items-center">
                <h2 className="text-xl font-bold">Ask AI</h2>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => onNewChat()}
                        className="p-2 rounded hover:bg-gray-100 transition-colors"
                        title="New Chat"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                    </button>
                    <button
                        onClick={() => setShowHistory(true)}
                        className="p-2 rounded hover:bg-gray-100 transition-colors"
                        title="Chat History"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
            
            <div className="flex-1 overflow-y-auto p-4">
                {messages.map((message, index) => (
                    <div key={index} className="mb-4">
                        {message.role === 'user' ? (
                            <div className="flex justify-end">
                                <div className="max-w-[80%] rounded-lg p-3 bg-gray-100">
                                    <div 
                                        className="markdown-body prose max-w-none"
                                        dangerouslySetInnerHTML={{ 
                                            __html: DOMPurify.sanitize(
                                                marked.parse(message.content, {
                                                    gfm: true,
                                                    breaks: true
                                                }) as string
                                            ) 
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="flex justify-start">
                                <div className="w-[95%] rounded-lg p-3 bg-blue-50">
                                    <div 
                                        className="markdown-body prose prose-light-blue max-w-none"
                                        dangerouslySetInnerHTML={{ 
                                            __html: DOMPurify.sanitize(
                                                marked.parse(message.content, {
                                                    gfm: true,
                                                    breaks: true
                                                }) as string
                                            ) 
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <form onSubmit={handleSubmit} className="p-4 border-t">
                <div className="flex flex-col gap-2">
                    <div className="relative">
                        <div className="mb-2">
                            <div className="flex flex-wrap items-center gap-1 min-h-[28px]">
                                <button
                                    type="button"
                                    onClick={handleFileSelect}
                                    className="w-6 h-6 flex items-center justify-center rounded border border-gray-300 text-gray-500 hover:text-gray-700 hover:border-gray-400 focus:outline-none focus:border-blue-500 bg-white"
                                >
                                    +
                                </button>
                                {attachedFiles.length > 0 && attachedFiles.map((file) => (
                                    <div 
                                        key={file.id}
                                        className="flex items-center gap-1 text-sm bg-gray-100 px-2 py-0.5 rounded-full text-gray-600"
                                    >
                                        <span className="truncate max-w-[200px]">{file.name}</span>
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveFile(file.id)}
                                            className="text-gray-400 hover:text-gray-600 ml-1"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex items-start gap-2">
                            <textarea
                                value={input}
                                onChange={(e) => {
                                    setInput(e.target.value);
                                    e.target.style.height = 'auto';
                                    e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`;
                                }}
                                placeholder="Ask a question..."
                                className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-y-auto max-h-[200px] min-h-[40px] overflow-x-hidden"
                                style={{ overflowY: input.split('\n').length > 8 ? 'scroll' : 'hidden' }}
                                rows={1}
                            />
                            <button
                                type="submit"
                                className="min-w-[70px] h-[40px] px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 flex-shrink-0 flex items-center justify-center"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[80vh] flex flex-col">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Select Files</h3>
                                <button
                                    onClick={handleModalClose}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    ×
                                </button>
                            </div>
                            <div className="flex-1 overflow-y-auto min-h-[300px] border rounded p-4">
                                {files.map(file => renderFileTree(file))}
                            </div>
                            <div className="flex justify-end gap-2 mt-4">
                                <button
                                    onClick={handleModalClose}
                                    className="px-4 py-2 border rounded hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmSelection}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default Chat;
