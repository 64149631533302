import React, { useRef, useEffect } from 'react';
import { FileNode } from '../types';
import hljs from 'highlight.js';

interface CodeViewerProps {
    selectedFile: FileNode | null;
    fileContent: string;
}

const CodeViewer: React.FC<CodeViewerProps> = ({ selectedFile, fileContent }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const scrollbarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const content = contentRef.current;
        const scrollbar = scrollbarRef.current;
        
        if (!content || !scrollbar) return;

        const handleContentScroll = () => {
            if (content && scrollbar) {
                scrollbar.scrollLeft = content.scrollLeft;
            }
        };

        const handleScrollbarScroll = () => {
            if (content && scrollbar) {
                content.scrollLeft = scrollbar.scrollLeft;
            }
        };

        content.addEventListener('scroll', handleContentScroll);
        scrollbar.addEventListener('scroll', handleScrollbarScroll);

        return () => {
            content.removeEventListener('scroll', handleContentScroll);
            scrollbar.removeEventListener('scroll', handleScrollbarScroll);
        };
    }, []);

    const isImageFile = (filename: string): boolean => {
        const ext = filename.split('.').pop()?.toLowerCase() || '';
        return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(ext);
    };

    const getLanguage = (filename: string): string => {
        const ext = filename.split('.').pop()?.toLowerCase() || '';
        const languageMap: { [key: string]: string } = {
            'js': 'javascript',
            'jsx': 'javascript',
            'ts': 'typescript',
            'tsx': 'typescript',
            'py': 'python',
            'html': 'html',
            'css': 'css',
            'json': 'json',
            'md': 'markdown',
        };
        return languageMap[ext] || '';
    };

    const highlightCode = (code: string, language: string): string => {
        if (language && hljs.getLanguage(language)) {
            try {
                return hljs.highlight(code, { language }).value;
            } catch (err) {
                console.error(err);
            }
        }
        return code;
    };

    if (!selectedFile) {
        return (
            <div className="flex-1 flex items-center justify-center bg-gray-50 text-gray-500">
                No file selected
            </div>
        );
    }

    if (isImageFile(selectedFile.name)) {
        return (
            <div className="flex-1 flex items-center justify-center bg-gray-50">
                <img 
                    src={fileContent} 
                    alt={selectedFile.name} 
                    className="max-w-full max-h-[90vh] object-contain"
                />
            </div>
        );
    }

    const language = getLanguage(selectedFile.name);
    const highlightedCode = highlightCode(fileContent, language);

    return (
        <div className="h-full flex flex-col">
            <div ref={contentRef} className="flex-1 overflow-y-auto overflow-x-auto">
                <pre className="p-4 min-w-max">
                    <code
                        className={`language-${language} whitespace-pre`}
                        dangerouslySetInnerHTML={{ __html: highlightedCode }}
                    />
                </pre>
            </div>
            <div ref={scrollbarRef} className="overflow-x-auto border-t">
                <div className="h-3">
                    <div style={{ width: contentRef.current?.scrollWidth }} />
                </div>
            </div>
        </div>
    );
};

export default CodeViewer;
