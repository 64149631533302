import React from 'react';
import { Message } from '../types';

interface ChatHistoryProps {
    onClose: () => void;
    visible: boolean;
    chats: Message[][];
    onSelectChat: (chatIndex: number) => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ onClose, visible, chats, onSelectChat }) => {
    if (!visible) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-[600px] max-h-[80vh] flex flex-col">
                <div className="p-4 border-b flex justify-between items-center">
                    <h2 className="text-xl font-bold">Chat History</h2>
                    <button
                        onClick={onClose}
                        className="p-2 rounded hover:bg-gray-100 transition-colors"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
                <div className="flex-1 overflow-y-auto p-4">
                    {chats.map((chat, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                onSelectChat(index);
                                onClose();
                            }}
                            className="w-full p-4 rounded mb-2 hover:bg-gray-50 border text-left transition-colors"
                        >
                            <div className="font-medium truncate">
                                {chat[0]?.content.split('\n')[0] || 'Empty chat'}
                            </div>
                            <div className="text-sm text-gray-500 mt-1">
                                {chat.length} messages
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChatHistory;
