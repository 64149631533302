import React, { useState } from 'react';
import { EditorSettings } from '../types';

interface EditorSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  settings: EditorSettings;
  onSave: (settings: EditorSettings) => void;
}

const EditorSettingsDialog: React.FC<EditorSettingsProps> = ({
  isOpen,
  onClose,
  settings: initialSettings,
  onSave,
}) => {
  const [settings, setSettings] = useState<EditorSettings>(initialSettings);

  if (!isOpen) return null;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: type === 'checkbox' 
        ? (e.target as HTMLInputElement).checked 
        : type === 'number' 
          ? Number(value) 
          : value,
    }));
  };

  const handleSave = () => {
    onSave(settings);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Editor Settings</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">✕</button>
        </div>
        
        <div className="space-y-4">
          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Theme</label>
            <select
              name="theme"
              value={settings.theme}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
            >
              <option value="vs">Light</option>
              <option value="vs-dark">Dark</option>
              <option value="hc-black">High Contrast</option>
            </select>
          </div>

          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Font Size</label>
            <input
              type="number"
              name="fontSize"
              value={settings.fontSize}
              onChange={handleChange}
              min={8}
              max={32}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="form-group">
            <label className="block text-sm font-medium mb-1">Tab Size</label>
            <input
              type="number"
              name="tabSize"
              value={settings.tabSize}
              onChange={handleChange}
              min={2}
              max={8}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="form-group">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="wordWrap"
                checked={settings.wordWrap}
                onChange={handleChange}
                className="rounded text-blue-500 focus:ring-2 focus:ring-blue-500"
              />
              <span className="text-sm font-medium">Word Wrap</span>
            </label>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditorSettingsDialog;
